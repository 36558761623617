// @ts-nocheck
// tslint:disable
/**
 * MLP Billing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AggregatedServerSpendHistoryDto
 */
export interface AggregatedServerSpendHistoryDto {
    /**
     * 
     * @type {number}
     * @memberof AggregatedServerSpendHistoryDto
     */
    serverId: number;
    /**
     * 
     * @type {string}
     * @memberof AggregatedServerSpendHistoryDto
     */
    serverName: string;
    /**
     * 
     * @type {number}
     * @memberof AggregatedServerSpendHistoryDto
     */
    amountCurrency: number;
    /**
     * 
     * @type {number}
     * @memberof AggregatedServerSpendHistoryDto
     */
    priceCurrency: number;
    /**
     * 
     * @type {string}
     * @memberof AggregatedServerSpendHistoryDto
     */
    pricePeriod: string;
    /**
     * 
     * @type {Date}
     * @memberof AggregatedServerSpendHistoryDto
     */
    rentFrom: Date;
    /**
     * 
     * @type {Date}
     * @memberof AggregatedServerSpendHistoryDto
     */
    rentTo: Date;
}
/**
 * 
 * @export
 * @interface Balance
 */
export interface Balance {
    /**
     * 
     * @type {number}
     * @memberof Balance
     */
    accountId: number;
    /**
     * 
     * @type {number}
     * @memberof Balance
     */
    version: number;
    /**
     * 
     * @type {number}
     * @memberof Balance
     */
    cloudTariffSpending?: number;
    /**
     * 
     * @type {number}
     * @memberof Balance
     */
    cloudTariffQuota?: number;
    /**
     * 
     * @type {number}
     * @memberof Balance
     */
    cloudPackageSpending?: number;
    /**
     * 
     * @type {number}
     * @memberof Balance
     */
    cloudPackageSpendingFromPeriodStart?: number;
    /**
     * 
     * @type {number}
     * @memberof Balance
     */
    cloudPackageQuota?: number;
    /**
     * 
     * @type {number}
     * @memberof Balance
     */
    cloudBalance?: number;
    /**
     * 
     * @type {number}
     * @memberof Balance
     */
    cloudNegativeBalanceTimestamp?: number;
    /**
     * 
     * @type {number}
     * @memberof Balance
     */
    incomeBalance?: number;
    /**
     * 
     * @type {number}
     * @memberof Balance
     */
    lastUpdateFromAA?: number;
    /**
     * 
     * @type {number}
     * @memberof Balance
     */
    lastResetUpdateFromAA?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Balance
     */
    cloudBlocked?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Balance
     */
    hasWelcomeBonus?: boolean;
}
/**
 * 
 * @export
 * @interface BatchSpendRequest
 */
export interface BatchSpendRequest {
    /**
     * 
     * @type {number}
     * @memberof BatchSpendRequest
     */
    accountId: number;
    /**
     * 
     * @type {string}
     * @memberof BatchSpendRequest
     */
    apiToken: string;
    /**
     * 
     * @type {number}
     * @memberof BatchSpendRequest
     */
    spendingAccountId: number;
    /**
     * 
     * @type {string}
     * @memberof BatchSpendRequest
     */
    spendingAccountName: string;
    /**
     * 
     * @type {number}
     * @memberof BatchSpendRequest
     */
    spendingModelId: number;
    /**
     * 
     * @type {string}
     * @memberof BatchSpendRequest
     */
    spendingModelName: string;
    /**
     * 
     * @type {number}
     * @memberof BatchSpendRequest
     */
    units: number;
    /**
     * 
     * @type {number}
     * @memberof BatchSpendRequest
     */
    nanoTokens: number;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof BatchSpendRequest
     */
    billingDetails?: { [key: string]: number; };
    /**
     * 
     * @type {number}
     * @memberof BatchSpendRequest
     */
    calls: number;
    /**
     * 
     * @type {boolean}
     * @memberof BatchSpendRequest
     */
    noIncomeDetails: boolean;
}
/**
 * 
 * @export
 * @interface BatchSpendRequests
 */
export interface BatchSpendRequests {
    /**
     * 
     * @type {Array<BatchSpendRequest>}
     * @memberof BatchSpendRequests
     */
    requests: Array<BatchSpendRequest>;
}
/**
 * 
 * @export
 * @interface CheckResult
 */
export interface CheckResult {
    /**
     * 
     * @type {string}
     * @memberof CheckResult
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof CheckResult
     */
    critical: boolean;
    /**
     * 
     * @type {Status}
     * @memberof CheckResult
     */
    status: Status;
}
/**
 * 
 * @export
 * @interface HealthCheckResult
 */
export interface HealthCheckResult {
    /**
     * 
     * @type {boolean}
     * @memberof HealthCheckResult
     */
    ok: boolean;
    /**
     * 
     * @type {string}
     * @memberof HealthCheckResult
     */
    status: string;
    /**
     * 
     * @type {Array<CheckResult>}
     * @memberof HealthCheckResult
     */
    checks: Array<CheckResult>;
}
/**
 * 
 * @export
 * @interface IncomeDetailsDto
 */
export interface IncomeDetailsDto {
    /**
     * 
     * @type {string}
     * @memberof IncomeDetailsDto
     */
    interval: IntervalEnum;
    /**
     * 
     * @type {string}
     * @memberof IncomeDetailsDto
     */
    date: string;
    /**
     * 
     * @type {number}
     * @memberof IncomeDetailsDto
     */
    currency: number;
    /**
     * 
     * @type {number}
     * @memberof IncomeDetailsDto
     */
    tokens: number;
    /**
     * 
     * @type {number}
     * @memberof IncomeDetailsDto
     */
    calls: number;
    /**
     * 
     * @type {Array<IncomeHistoryItem>}
     * @memberof IncomeDetailsDto
     */
    incomes: Array<IncomeHistoryItem>;
    /**
     * 
     * @type {number}
     * @memberof IncomeDetailsDto
     */
    version: number;
}

/**
    * @export
    * @enum {string}
    */
export enum IntervalEnum {
    HOUR = 'HOUR',
    DAY = 'DAY',
    MONTH = 'MONTH',
    YEAR = 'YEAR'
}

/**
 * 
 * @export
 * @interface IncomeHistoryItem
 */
export interface IncomeHistoryItem {
    /**
     * 
     * @type {string}
     * @memberof IncomeHistoryItem
     */
    model: string;
    /**
     * 
     * @type {number}
     * @memberof IncomeHistoryItem
     */
    calls: number;
    /**
     * 
     * @type {number}
     * @memberof IncomeHistoryItem
     */
    currency: number;
    /**
     * 
     * @type {number}
     * @memberof IncomeHistoryItem
     */
    tokens: number;
    /**
     * 
     * @type {number}
     * @memberof IncomeHistoryItem
     */
    units: number;
}
/**
 * 
 * @export
 * @interface IncomeMetricsResponse
 */
export interface IncomeMetricsResponse {
    /**
     * 
     * @type {number}
     * @memberof IncomeMetricsResponse
     */
    accountId: number;
    /**
     * 
     * @type {number}
     * @memberof IncomeMetricsResponse
     */
    totalCurrency: number;
    /**
     * 
     * @type {Array<IncomeModelResponse>}
     * @memberof IncomeMetricsResponse
     */
    models: Array<IncomeModelResponse>;
}
/**
 * 
 * @export
 * @interface IncomeModelResponse
 */
export interface IncomeModelResponse {
    /**
     * 
     * @type {string}
     * @memberof IncomeModelResponse
     */
    model: string;
    /**
     * 
     * @type {number}
     * @memberof IncomeModelResponse
     */
    currency: number;
}
/**
 * 
 * @export
 * @interface LimitsAndBalance
 */
export interface LimitsAndBalance {
    /**
     * 
     * @type {number}
     * @memberof LimitsAndBalance
     */
    accountId: number;
    /**
     * 
     * @type {number}
     * @memberof LimitsAndBalance
     */
    mlpTokensTariffLimit: number;
    /**
     * 
     * @type {number}
     * @memberof LimitsAndBalance
     */
    mlpTokensUsedTariffLimit: number;
    /**
     * 
     * @type {number}
     * @memberof LimitsAndBalance
     */
    mlpTokensPackageLimit: number;
    /**
     * 
     * @type {number}
     * @memberof LimitsAndBalance
     */
    mlpTokensUsedPackageLimit: number;
    /**
     * 
     * @type {number}
     * @memberof LimitsAndBalance
     */
    mlpTokensUsedPackageLimitWhenTariffPeriodStarted: number;
}
/**
 * 
 * @export
 * @interface LimitsAndBalances
 */
export interface LimitsAndBalances {
    /**
     * 
     * @type {Array<LimitsAndBalance>}
     * @memberof LimitsAndBalances
     */
    limits: Array<LimitsAndBalance>;
}
/**
 * 
 * @export
 * @interface LimitsRequest
 */
export interface LimitsRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof LimitsRequest
     */
    accountIds: Array<number>;
}
/**
 * 
 * @export
 * @interface ObjectId
 */
export interface ObjectId {
    /**
     * 
     * @type {number}
     * @memberof ObjectId
     */
    timestamp?: number;
    /**
     * 
     * @type {Date}
     * @memberof ObjectId
     */
    date?: Date;
}
/**
 * 
 * @export
 * @interface RefillFromCardDynamicRequest
 */
export interface RefillFromCardDynamicRequest {
    /**
     * 
     * @type {number}
     * @memberof RefillFromCardDynamicRequest
     */
    accountId: number;
    /**
     * 
     * @type {number}
     * @memberof RefillFromCardDynamicRequest
     */
    amountCurrency: number;
    /**
     * 
     * @type {string}
     * @memberof RefillFromCardDynamicRequest
     */
    redirectUrl: string;
}
/**
 * 
 * @export
 * @interface RefillFromCardResponse
 */
export interface RefillFromCardResponse {
    /**
     * 
     * @type {string}
     * @memberof RefillFromCardResponse
     */
    paymentUrl?: string;
}
/**
 * 
 * @export
 * @interface RefillFromIncomeRequest
 */
export interface RefillFromIncomeRequest {
    /**
     * 
     * @type {number}
     * @memberof RefillFromIncomeRequest
     */
    accountId: number;
    /**
     * 
     * @type {number}
     * @memberof RefillFromIncomeRequest
     */
    amountCurrency: number;
}
/**
 * 
 * @export
 * @interface RefillHistory
 */
export interface RefillHistory {
    /**
     * 
     * @type {ObjectId}
     * @memberof RefillHistory
     */
    id: ObjectId;
    /**
     * 
     * @type {number}
     * @memberof RefillHistory
     */
    accountId: number;
    /**
     * 
     * @type {Date}
     * @memberof RefillHistory
     */
    timestamp: Date;
    /**
     * 
     * @type {number}
     * @memberof RefillHistory
     */
    cloudBalanceBefore: number;
    /**
     * 
     * @type {number}
     * @memberof RefillHistory
     */
    cloudBalanceAfter: number;
    /**
     * 
     * @type {boolean}
     * @memberof RefillHistory
     */
    cloudBlockedBefore?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RefillHistory
     */
    cloudBlockedAfter?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RefillHistory
     */
    tariffQuotaBefore?: number;
    /**
     * 
     * @type {number}
     * @memberof RefillHistory
     */
    tariffQuotaAfter?: number;
    /**
     * 
     * @type {number}
     * @memberof RefillHistory
     */
    tariffSpendingBefore?: number;
    /**
     * 
     * @type {number}
     * @memberof RefillHistory
     */
    tariffSpendingAfter?: number;
    /**
     * 
     * @type {number}
     * @memberof RefillHistory
     */
    packageQuotaBefore?: number;
    /**
     * 
     * @type {number}
     * @memberof RefillHistory
     */
    packageQuotaAfter?: number;
    /**
     * 
     * @type {number}
     * @memberof RefillHistory
     */
    packageSpendingBefore?: number;
    /**
     * 
     * @type {number}
     * @memberof RefillHistory
     */
    packageSpendingAfter?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RefillHistory
     */
    reset?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RefillHistory
     */
    reason?: ReasonEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ReasonEnum {
    REQUESTFROMAA = 'REQUEST_FROM_AA',
    REFILLFROMINCOME = 'REFILL_FROM_INCOME'
}

/**
 * 
 * @export
 * @interface ResourceGroupSpendings
 */
export interface ResourceGroupSpendings {
    /**
     * 
     * @type {string}
     * @memberof ResourceGroupSpendings
     */
    resourceGroupName: string;
    /**
     * 
     * @type {number}
     * @memberof ResourceGroupSpendings
     */
    amountCurrency: number;
    /**
     * 
     * @type {Array<AggregatedServerSpendHistoryDto>}
     * @memberof ResourceGroupSpendings
     */
    servers: Array<AggregatedServerSpendHistoryDto>;
}
/**
 * 
 * @export
 * @interface ServerSpendHistory
 */
export interface ServerSpendHistory {
    /**
     * 
     * @type {number}
     * @memberof ServerSpendHistory
     */
    serverOwnerAccountId: number;
    /**
     * 
     * @type {number}
     * @memberof ServerSpendHistory
     */
    serverId: number;
    /**
     * 
     * @type {string}
     * @memberof ServerSpendHistory
     */
    serverName: string;
    /**
     * 
     * @type {Date}
     * @memberof ServerSpendHistory
     */
    serverCreateAt: Date;
    /**
     * 
     * @type {string}
     * @memberof ServerSpendHistory
     */
    resourceGroupName: string;
    /**
     * 
     * @type {string}
     * @memberof ServerSpendHistory
     */
    transactionId: string;
    /**
     * 
     * @type {number}
     * @memberof ServerSpendHistory
     */
    spentAmountNanoTokens: number;
    /**
     * 
     * @type {number}
     * @memberof ServerSpendHistory
     */
    costAmountNanoTokens: number;
    /**
     * 
     * @type {number}
     * @memberof ServerSpendHistory
     */
    priceForPeriodNanoTokens: number;
    /**
     * 
     * @type {number}
     * @memberof ServerSpendHistory
     */
    costForPeriodNanoTokens: number;
    /**
     * 
     * @type {string}
     * @memberof ServerSpendHistory
     */
    period: string;
    /**
     * 
     * @type {Date}
     * @memberof ServerSpendHistory
     */
    paidFrom: Date;
    /**
     * 
     * @type {Date}
     * @memberof ServerSpendHistory
     */
    paidTo: Date;
    /**
     * 
     * @type {number}
     * @memberof ServerSpendHistory
     */
    incomeAccountId: number;
}
/**
 * 
 * @export
 * @interface ServerSpendHistoryDto
 */
export interface ServerSpendHistoryDto {
    /**
     * 
     * @type {number}
     * @memberof ServerSpendHistoryDto
     */
    serverOwnerAccountId: number;
    /**
     * 
     * @type {number}
     * @memberof ServerSpendHistoryDto
     */
    serverId: number;
    /**
     * 
     * @type {string}
     * @memberof ServerSpendHistoryDto
     */
    serverName: string;
    /**
     * 
     * @type {Date}
     * @memberof ServerSpendHistoryDto
     */
    serverCreatedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof ServerSpendHistoryDto
     */
    resourceGroupName: string;
    /**
     * 
     * @type {string}
     * @memberof ServerSpendHistoryDto
     */
    transactionId: string;
    /**
     * 
     * @type {number}
     * @memberof ServerSpendHistoryDto
     */
    amountCurrency: number;
    /**
     * 
     * @type {number}
     * @memberof ServerSpendHistoryDto
     */
    priceForPeriodCurrency: number;
    /**
     * 
     * @type {string}
     * @memberof ServerSpendHistoryDto
     */
    period: string;
    /**
     * 
     * @type {Date}
     * @memberof ServerSpendHistoryDto
     */
    paidFrom: Date;
    /**
     * 
     * @type {Date}
     * @memberof ServerSpendHistoryDto
     */
    paidTo: Date;
}
/**
 * 
 * @export
 * @interface ServerSpendRequest
 */
export interface ServerSpendRequest {
    /**
     * 
     * @type {number}
     * @memberof ServerSpendRequest
     */
    serverOwnerAccountId: number;
    /**
     * 
     * @type {number}
     * @memberof ServerSpendRequest
     */
    serverId: number;
    /**
     * 
     * @type {string}
     * @memberof ServerSpendRequest
     */
    serverName: string;
    /**
     * 
     * @type {number}
     * @memberof ServerSpendRequest
     */
    serverCreatedAt: number;
    /**
     * 
     * @type {string}
     * @memberof ServerSpendRequest
     */
    resourceGroupName: string;
    /**
     * 
     * @type {string}
     * @memberof ServerSpendRequest
     */
    transactionId: string;
    /**
     * 
     * @type {number}
     * @memberof ServerSpendRequest
     */
    amountCurrency: number;
    /**
     * 
     * @type {number}
     * @memberof ServerSpendRequest
     */
    priceForPeriodCurrency: number;
    /**
     * 
     * @type {string}
     * @memberof ServerSpendRequest
     */
    period: string;
    /**
     * 
     * @type {number}
     * @memberof ServerSpendRequest
     */
    paidFrom: number;
    /**
     * 
     * @type {number}
     * @memberof ServerSpendRequest
     */
    paidTo: number;
}
/**
 * 
 * @export
 * @interface ServerSpendRequestV2
 */
export interface ServerSpendRequestV2 {
    /**
     * 
     * @type {number}
     * @memberof ServerSpendRequestV2
     */
    serverOwnerAccountId: number;
    /**
     * 
     * @type {number}
     * @memberof ServerSpendRequestV2
     */
    serverId: number;
    /**
     * 
     * @type {string}
     * @memberof ServerSpendRequestV2
     */
    serverName: string;
    /**
     * 
     * @type {number}
     * @memberof ServerSpendRequestV2
     */
    serverCreatedAt: number;
    /**
     * 
     * @type {string}
     * @memberof ServerSpendRequestV2
     */
    resourceGroupName: string;
    /**
     * 
     * @type {string}
     * @memberof ServerSpendRequestV2
     */
    transactionId: string;
    /**
     * 
     * @type {number}
     * @memberof ServerSpendRequestV2
     */
    spentAmountCurrency: number;
    /**
     * 
     * @type {number}
     * @memberof ServerSpendRequestV2
     */
    costAmountCurrency: number;
    /**
     * 
     * @type {number}
     * @memberof ServerSpendRequestV2
     */
    priceForPeriodCurrency: number;
    /**
     * 
     * @type {number}
     * @memberof ServerSpendRequestV2
     */
    costForPeriodCurrency: number;
    /**
     * 
     * @type {string}
     * @memberof ServerSpendRequestV2
     */
    period: string;
    /**
     * 
     * @type {number}
     * @memberof ServerSpendRequestV2
     */
    paidFrom: number;
    /**
     * 
     * @type {number}
     * @memberof ServerSpendRequestV2
     */
    paidTo: number;
    /**
     * 
     * @type {number}
     * @memberof ServerSpendRequestV2
     */
    incomeAccountId: number;
}
/**
 * 
 * @export
 * @interface ServerSpendingDetailsDto
 */
export interface ServerSpendingDetailsDto {
    /**
     * 
     * @type {string}
     * @memberof ServerSpendingDetailsDto
     */
    interval: IntervalEnum;
    /**
     * 
     * @type {string}
     * @memberof ServerSpendingDetailsDto
     */
    date: string;
    /**
     * 
     * @type {number}
     * @memberof ServerSpendingDetailsDto
     */
    amountCurrency: number;
    /**
     * 
     * @type {Array<ResourceGroupSpendings>}
     * @memberof ServerSpendingDetailsDto
     */
    resourceGroups: Array<ResourceGroupSpendings>;
}

/**
    * @export
    * @enum {string}
    */
export enum IntervalEnum {
    HOUR = 'HOUR',
    DAY = 'DAY',
    MONTH = 'MONTH',
    YEAR = 'YEAR'
}

/**
 * 
 * @export
 * @interface ServerSpendingDetailsListDto
 */
export interface ServerSpendingDetailsListDto {
    /**
     * 
     * @type {Array<ServerSpendHistoryDto>}
     * @memberof ServerSpendingDetailsListDto
     */
    spendings: Array<ServerSpendHistoryDto>;
}
/**
 * 
 * @export
 * @interface ShortBalance
 */
export interface ShortBalance {
    /**
     * 
     * @type {number}
     * @memberof ShortBalance
     */
    overallBalanceCurrency?: number;
    /**
     * 
     * @type {number}
     * @memberof ShortBalance
     */
    jaicpTariffBalanceCurrency?: number;
    /**
     * 
     * @type {number}
     * @memberof ShortBalance
     */
    incomeBalanceCurrency?: number;
    /**
     * 
     * @type {number}
     * @memberof ShortBalance
     */
    nextRefillCurrency?: number;
    /**
     * 
     * @type {Date}
     * @memberof ShortBalance
     */
    nextRefillDate?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof ShortBalance
     */
    isBlocked: boolean;
}
/**
 * 
 * @export
 * @interface SpendRequest
 */
export interface SpendRequest {
    /**
     * 
     * @type {number}
     * @memberof SpendRequest
     */
    accountId: number;
    /**
     * 
     * @type {string}
     * @memberof SpendRequest
     */
    apiToken: string;
    /**
     * 
     * @type {number}
     * @memberof SpendRequest
     */
    spendingAccountId: number;
    /**
     * 
     * @type {string}
     * @memberof SpendRequest
     */
    spendingAccountName: string;
    /**
     * 
     * @type {number}
     * @memberof SpendRequest
     */
    spendingModelId: number;
    /**
     * 
     * @type {string}
     * @memberof SpendRequest
     */
    spendingModelName: string;
    /**
     * 
     * @type {number}
     * @memberof SpendRequest
     */
    units: number;
    /**
     * 
     * @type {number}
     * @memberof SpendRequest
     */
    nanoTokens: number;
}
/**
 * 
 * @export
 * @interface SpendRequests
 */
export interface SpendRequests {
    /**
     * 
     * @type {Array<SpendRequest>}
     * @memberof SpendRequests
     */
    requests: Array<SpendRequest>;
}
/**
 * 
 * @export
 * @interface SpendingDetailsDto
 */
export interface SpendingDetailsDto {
    /**
     * 
     * @type {string}
     * @memberof SpendingDetailsDto
     */
    interval: IntervalEnum;
    /**
     * 
     * @type {string}
     * @memberof SpendingDetailsDto
     */
    date: string;
    /**
     * 
     * @type {number}
     * @memberof SpendingDetailsDto
     */
    currency: number;
    /**
     * 
     * @type {number}
     * @memberof SpendingDetailsDto
     */
    tokens: number;
    /**
     * 
     * @type {number}
     * @memberof SpendingDetailsDto
     */
    calls: number;
    /**
     * 
     * @type {Array<SpendingHistoryPerApiToken>}
     * @memberof SpendingDetailsDto
     */
    spendings: Array<SpendingHistoryPerApiToken>;
    /**
     * 
     * @type {number}
     * @memberof SpendingDetailsDto
     */
    version: number;
}

/**
    * @export
    * @enum {string}
    */
export enum IntervalEnum {
    HOUR = 'HOUR',
    DAY = 'DAY',
    MONTH = 'MONTH',
    YEAR = 'YEAR'
}

/**
 * 
 * @export
 * @interface SpendingHistoryPerApiToken
 */
export interface SpendingHistoryPerApiToken {
    /**
     * 
     * @type {string}
     * @memberof SpendingHistoryPerApiToken
     */
    apiToken: string;
    /**
     * 
     * @type {number}
     * @memberof SpendingHistoryPerApiToken
     */
    currency: number;
    /**
     * 
     * @type {number}
     * @memberof SpendingHistoryPerApiToken
     */
    tokens: number;
    /**
     * 
     * @type {number}
     * @memberof SpendingHistoryPerApiToken
     */
    calls: number;
    /**
     * 
     * @type {Array<SpendingItem>}
     * @memberof SpendingHistoryPerApiToken
     */
    services: Array<SpendingItem>;
}
/**
 * 
 * @export
 * @interface SpendingItem
 */
export interface SpendingItem {
    /**
     * 
     * @type {string}
     * @memberof SpendingItem
     */
    account: string;
    /**
     * 
     * @type {string}
     * @memberof SpendingItem
     */
    model: string;
    /**
     * 
     * @type {number}
     * @memberof SpendingItem
     */
    calls: number;
    /**
     * 
     * @type {number}
     * @memberof SpendingItem
     */
    currency: number;
    /**
     * 
     * @type {number}
     * @memberof SpendingItem
     */
    tokens: number;
    /**
     * 
     * @type {number}
     * @memberof SpendingItem
     */
    units: number;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof SpendingItem
     */
    billingDetails: { [key: string]: number; };
}
/**
 * 
 * @export
 * @interface SpendingMetricsResponse
 */
export interface SpendingMetricsResponse {
    /**
     * 
     * @type {number}
     * @memberof SpendingMetricsResponse
     */
    accountId: number;
    /**
     * 
     * @type {number}
     * @memberof SpendingMetricsResponse
     */
    totalCurrency: number;
    /**
     * 
     * @type {Array<SpendingModelResponse>}
     * @memberof SpendingMetricsResponse
     */
    models: Array<SpendingModelResponse>;
}
/**
 * 
 * @export
 * @interface SpendingModelResponse
 */
export interface SpendingModelResponse {
    /**
     * 
     * @type {string}
     * @memberof SpendingModelResponse
     */
    model: string;
    /**
     * 
     * @type {string}
     * @memberof SpendingModelResponse
     */
    modelAccount: string;
    /**
     * 
     * @type {number}
     * @memberof SpendingModelResponse
     */
    currency: number;
}
/**
 * 
 * @export
 * @interface Status
 */
export interface Status {
    /**
     * 
     * @type {boolean}
     * @memberof Status
     */
    ok: boolean;
    /**
     * 
     * @type {string}
     * @memberof Status
     */
    message: string;
}
/**
 * 
 * @export
 * @interface TopEarningModelResponse
 */
export interface TopEarningModelResponse {
    /**
     * 
     * @type {string}
     * @memberof TopEarningModelResponse
     */
    model: string;
    /**
     * 
     * @type {number}
     * @memberof TopEarningModelResponse
     */
    modelAccountId: number;
    /**
     * 
     * @type {number}
     * @memberof TopEarningModelResponse
     */
    totalCurrency: number;
}
/**
 * 
 * @export
 * @interface UpdateLimitsRequest
 */
export interface UpdateLimitsRequest {
    /**
     * 
     * @type {number}
     * @memberof UpdateLimitsRequest
     */
    accountId: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateLimitsRequest
     */
    mlpTokensTariffLimit: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateLimitsRequest
     */
    mlpTokensPackageLimit: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateLimitsRequest
     */
    reset: boolean;
}

/**
 * AaCallbackEndpointApi - axios parameter creator
 * @export
 */
export const AaCallbackEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLimits(accountId: number, options: any = {}): RequestArgs {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling getLimits.');
            }
            const localVarPath = `/api/mlpbilling/account-limits/{accountId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LimitsRequest} limitsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMultipleLimits(limitsRequest: LimitsRequest, options: any = {}): RequestArgs {
            // verify required parameter 'limitsRequest' is not null or undefined
            if (limitsRequest === null || limitsRequest === undefined) {
                throw new RequiredError('limitsRequest','Required parameter limitsRequest was null or undefined when calling getMultipleLimits.');
            }
            const localVarPath = `/api/mlpbilling/account-limits/list`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof limitsRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(limitsRequest !== undefined ? limitsRequest : {}) : (limitsRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateLimitsRequest} updateLimitsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setLimits(updateLimitsRequest: UpdateLimitsRequest, options: any = {}): RequestArgs {
            // verify required parameter 'updateLimitsRequest' is not null or undefined
            if (updateLimitsRequest === null || updateLimitsRequest === undefined) {
                throw new RequiredError('updateLimitsRequest','Required parameter updateLimitsRequest was null or undefined when calling setLimits.');
            }
            const localVarPath = `/api/mlpbilling/account-limits`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof updateLimitsRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateLimitsRequest !== undefined ? updateLimitsRequest : {}) : (updateLimitsRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AaCallbackEndpointApi - functional programming interface
 * @export
 */
export const AaCallbackEndpointApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLimits(accountId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LimitsAndBalance> {
            const localVarAxiosArgs = AaCallbackEndpointApiAxiosParamCreator(configuration).getLimits(accountId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {LimitsRequest} limitsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMultipleLimits(limitsRequest: LimitsRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LimitsAndBalances> {
            const localVarAxiosArgs = AaCallbackEndpointApiAxiosParamCreator(configuration).getMultipleLimits(limitsRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {UpdateLimitsRequest} updateLimitsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setLimits(updateLimitsRequest: UpdateLimitsRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = AaCallbackEndpointApiAxiosParamCreator(configuration).setLimits(updateLimitsRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AaCallbackEndpointApi - factory interface
 * @export
 */
export const AaCallbackEndpointApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLimits(accountId: number, options?: any): AxiosPromise<LimitsAndBalance> {
            return AaCallbackEndpointApiFp(configuration).getLimits(accountId, options)(axios, basePath);
        },
        /**
         * 
         * @param {LimitsRequest} limitsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMultipleLimits(limitsRequest: LimitsRequest, options?: any): AxiosPromise<LimitsAndBalances> {
            return AaCallbackEndpointApiFp(configuration).getMultipleLimits(limitsRequest, options)(axios, basePath);
        },
        /**
         * 
         * @param {UpdateLimitsRequest} updateLimitsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setLimits(updateLimitsRequest: UpdateLimitsRequest, options?: any): AxiosPromise<void> {
            return AaCallbackEndpointApiFp(configuration).setLimits(updateLimitsRequest, options)(axios, basePath);
        },
    };
};

/**
 * AaCallbackEndpointApi - object-oriented interface
 * @export
 * @class AaCallbackEndpointApi
 * @extends {BaseAPI}
 */
export class AaCallbackEndpointApi extends BaseAPI {
    /**
     * 
     * @param {number} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AaCallbackEndpointApi
     */
    public getLimits(accountId: number, options?: any) {
        return AaCallbackEndpointApiFp(this.configuration).getLimits(accountId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {LimitsRequest} limitsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AaCallbackEndpointApi
     */
    public getMultipleLimits(limitsRequest: LimitsRequest, options?: any) {
        return AaCallbackEndpointApiFp(this.configuration).getMultipleLimits(limitsRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {UpdateLimitsRequest} updateLimitsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AaCallbackEndpointApi
     */
    public setLimits(updateLimitsRequest: UpdateLimitsRequest, options?: any) {
        return AaCallbackEndpointApiFp(this.configuration).setLimits(updateLimitsRequest, options)(this.axios, this.basePath);
    }

}


/**
 * BalanceInfoEndpointApi - axios parameter creator
 * @export
 */
export const BalanceInfoEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkBlocked(accountId: number, options: any = {}): RequestArgs {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling checkBlocked.');
            }
            const localVarPath = `/api/mlpbilling/account/{accountId}/blocked`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBalance(accountId: number, options: any = {}): RequestArgs {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling getBalance.');
            }
            const localVarPath = `/api/mlpbilling/account/{accountId}/balance`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} accountId 
         * @param {'HOUR' | 'DAY' | 'MONTH' | 'YEAR'} period 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIncomeHistory(accountId: number, period: 'HOUR' | 'DAY' | 'MONTH' | 'YEAR', date: string, options: any = {}): RequestArgs {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling getIncomeHistory.');
            }
            // verify required parameter 'period' is not null or undefined
            if (period === null || period === undefined) {
                throw new RequiredError('period','Required parameter period was null or undefined when calling getIncomeHistory.');
            }
            // verify required parameter 'date' is not null or undefined
            if (date === null || date === undefined) {
                throw new RequiredError('date','Required parameter date was null or undefined when calling getIncomeHistory.');
            }
            const localVarPath = `/api/mlpbilling/account/{accountId}/details/income`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }

            if (period !== undefined) {
                localVarQueryParameter['period'] = period;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} accountId 
         * @param {'HOUR' | 'DAY' | 'MONTH' | 'YEAR'} period 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServerSpendingHistory(accountId: number, period: 'HOUR' | 'DAY' | 'MONTH' | 'YEAR', date: string, options: any = {}): RequestArgs {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling getServerSpendingHistory.');
            }
            // verify required parameter 'period' is not null or undefined
            if (period === null || period === undefined) {
                throw new RequiredError('period','Required parameter period was null or undefined when calling getServerSpendingHistory.');
            }
            // verify required parameter 'date' is not null or undefined
            if (date === null || date === undefined) {
                throw new RequiredError('date','Required parameter date was null or undefined when calling getServerSpendingHistory.');
            }
            const localVarPath = `/api/mlpbilling/account/{accountId}/details/spending/server`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }

            if (period !== undefined) {
                localVarQueryParameter['period'] = period;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} accountId 
         * @param {'HOUR' | 'DAY' | 'MONTH' | 'YEAR'} period 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServerSpendingHistoryAsList(accountId: number, period: 'HOUR' | 'DAY' | 'MONTH' | 'YEAR', date: string, options: any = {}): RequestArgs {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling getServerSpendingHistoryAsList.');
            }
            // verify required parameter 'period' is not null or undefined
            if (period === null || period === undefined) {
                throw new RequiredError('period','Required parameter period was null or undefined when calling getServerSpendingHistoryAsList.');
            }
            // verify required parameter 'date' is not null or undefined
            if (date === null || date === undefined) {
                throw new RequiredError('date','Required parameter date was null or undefined when calling getServerSpendingHistoryAsList.');
            }
            const localVarPath = `/api/mlpbilling/account/{accountId}/details/spending/server/list`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }

            if (period !== undefined) {
                localVarQueryParameter['period'] = period;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShortBalance(accountId: number, options: any = {}): RequestArgs {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling getShortBalance.');
            }
            const localVarPath = `/api/mlpbilling/account/{accountId}/balance/short`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} accountId 
         * @param {'HOUR' | 'DAY' | 'MONTH' | 'YEAR'} period 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSpendingHistory(accountId: number, period: 'HOUR' | 'DAY' | 'MONTH' | 'YEAR', date: string, options: any = {}): RequestArgs {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling getSpendingHistory.');
            }
            // verify required parameter 'period' is not null or undefined
            if (period === null || period === undefined) {
                throw new RequiredError('period','Required parameter period was null or undefined when calling getSpendingHistory.');
            }
            // verify required parameter 'date' is not null or undefined
            if (date === null || date === undefined) {
                throw new RequiredError('date','Required parameter date was null or undefined when calling getSpendingHistory.');
            }
            const localVarPath = `/api/mlpbilling/account/{accountId}/details/spending`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }

            if (period !== undefined) {
                localVarQueryParameter['period'] = period;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BalanceInfoEndpointApi - functional programming interface
 * @export
 */
export const BalanceInfoEndpointApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkBlocked(accountId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean> {
            const localVarAxiosArgs = BalanceInfoEndpointApiAxiosParamCreator(configuration).checkBlocked(accountId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBalance(accountId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Balance> {
            const localVarAxiosArgs = BalanceInfoEndpointApiAxiosParamCreator(configuration).getBalance(accountId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} accountId 
         * @param {'HOUR' | 'DAY' | 'MONTH' | 'YEAR'} period 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIncomeHistory(accountId: number, period: 'HOUR' | 'DAY' | 'MONTH' | 'YEAR', date: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncomeDetailsDto> {
            const localVarAxiosArgs = BalanceInfoEndpointApiAxiosParamCreator(configuration).getIncomeHistory(accountId, period, date, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} accountId 
         * @param {'HOUR' | 'DAY' | 'MONTH' | 'YEAR'} period 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServerSpendingHistory(accountId: number, period: 'HOUR' | 'DAY' | 'MONTH' | 'YEAR', date: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServerSpendingDetailsDto> {
            const localVarAxiosArgs = BalanceInfoEndpointApiAxiosParamCreator(configuration).getServerSpendingHistory(accountId, period, date, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} accountId 
         * @param {'HOUR' | 'DAY' | 'MONTH' | 'YEAR'} period 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServerSpendingHistoryAsList(accountId: number, period: 'HOUR' | 'DAY' | 'MONTH' | 'YEAR', date: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServerSpendingDetailsListDto> {
            const localVarAxiosArgs = BalanceInfoEndpointApiAxiosParamCreator(configuration).getServerSpendingHistoryAsList(accountId, period, date, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShortBalance(accountId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShortBalance> {
            const localVarAxiosArgs = BalanceInfoEndpointApiAxiosParamCreator(configuration).getShortBalance(accountId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} accountId 
         * @param {'HOUR' | 'DAY' | 'MONTH' | 'YEAR'} period 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSpendingHistory(accountId: number, period: 'HOUR' | 'DAY' | 'MONTH' | 'YEAR', date: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SpendingDetailsDto> {
            const localVarAxiosArgs = BalanceInfoEndpointApiAxiosParamCreator(configuration).getSpendingHistory(accountId, period, date, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * BalanceInfoEndpointApi - factory interface
 * @export
 */
export const BalanceInfoEndpointApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkBlocked(accountId: number, options?: any): AxiosPromise<boolean> {
            return BalanceInfoEndpointApiFp(configuration).checkBlocked(accountId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBalance(accountId: number, options?: any): AxiosPromise<Balance> {
            return BalanceInfoEndpointApiFp(configuration).getBalance(accountId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} accountId 
         * @param {'HOUR' | 'DAY' | 'MONTH' | 'YEAR'} period 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIncomeHistory(accountId: number, period: 'HOUR' | 'DAY' | 'MONTH' | 'YEAR', date: string, options?: any): AxiosPromise<IncomeDetailsDto> {
            return BalanceInfoEndpointApiFp(configuration).getIncomeHistory(accountId, period, date, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} accountId 
         * @param {'HOUR' | 'DAY' | 'MONTH' | 'YEAR'} period 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServerSpendingHistory(accountId: number, period: 'HOUR' | 'DAY' | 'MONTH' | 'YEAR', date: string, options?: any): AxiosPromise<ServerSpendingDetailsDto> {
            return BalanceInfoEndpointApiFp(configuration).getServerSpendingHistory(accountId, period, date, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} accountId 
         * @param {'HOUR' | 'DAY' | 'MONTH' | 'YEAR'} period 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServerSpendingHistoryAsList(accountId: number, period: 'HOUR' | 'DAY' | 'MONTH' | 'YEAR', date: string, options?: any): AxiosPromise<ServerSpendingDetailsListDto> {
            return BalanceInfoEndpointApiFp(configuration).getServerSpendingHistoryAsList(accountId, period, date, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShortBalance(accountId: number, options?: any): AxiosPromise<ShortBalance> {
            return BalanceInfoEndpointApiFp(configuration).getShortBalance(accountId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} accountId 
         * @param {'HOUR' | 'DAY' | 'MONTH' | 'YEAR'} period 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSpendingHistory(accountId: number, period: 'HOUR' | 'DAY' | 'MONTH' | 'YEAR', date: string, options?: any): AxiosPromise<SpendingDetailsDto> {
            return BalanceInfoEndpointApiFp(configuration).getSpendingHistory(accountId, period, date, options)(axios, basePath);
        },
    };
};

/**
 * BalanceInfoEndpointApi - object-oriented interface
 * @export
 * @class BalanceInfoEndpointApi
 * @extends {BaseAPI}
 */
export class BalanceInfoEndpointApi extends BaseAPI {
    /**
     * 
     * @param {number} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BalanceInfoEndpointApi
     */
    public checkBlocked(accountId: number, options?: any) {
        return BalanceInfoEndpointApiFp(this.configuration).checkBlocked(accountId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BalanceInfoEndpointApi
     */
    public getBalance(accountId: number, options?: any) {
        return BalanceInfoEndpointApiFp(this.configuration).getBalance(accountId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} accountId 
     * @param {'HOUR' | 'DAY' | 'MONTH' | 'YEAR'} period 
     * @param {string} date 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BalanceInfoEndpointApi
     */
    public getIncomeHistory(accountId: number, period: 'HOUR' | 'DAY' | 'MONTH' | 'YEAR', date: string, options?: any) {
        return BalanceInfoEndpointApiFp(this.configuration).getIncomeHistory(accountId, period, date, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} accountId 
     * @param {'HOUR' | 'DAY' | 'MONTH' | 'YEAR'} period 
     * @param {string} date 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BalanceInfoEndpointApi
     */
    public getServerSpendingHistory(accountId: number, period: 'HOUR' | 'DAY' | 'MONTH' | 'YEAR', date: string, options?: any) {
        return BalanceInfoEndpointApiFp(this.configuration).getServerSpendingHistory(accountId, period, date, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} accountId 
     * @param {'HOUR' | 'DAY' | 'MONTH' | 'YEAR'} period 
     * @param {string} date 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BalanceInfoEndpointApi
     */
    public getServerSpendingHistoryAsList(accountId: number, period: 'HOUR' | 'DAY' | 'MONTH' | 'YEAR', date: string, options?: any) {
        return BalanceInfoEndpointApiFp(this.configuration).getServerSpendingHistoryAsList(accountId, period, date, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BalanceInfoEndpointApi
     */
    public getShortBalance(accountId: number, options?: any) {
        return BalanceInfoEndpointApiFp(this.configuration).getShortBalance(accountId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} accountId 
     * @param {'HOUR' | 'DAY' | 'MONTH' | 'YEAR'} period 
     * @param {string} date 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BalanceInfoEndpointApi
     */
    public getSpendingHistory(accountId: number, period: 'HOUR' | 'DAY' | 'MONTH' | 'YEAR', date: string, options?: any) {
        return BalanceInfoEndpointApiFp(this.configuration).getSpendingHistory(accountId, period, date, options)(this.axios, this.basePath);
    }

}


/**
 * BusinessMetricEndpointApi - axios parameter creator
 * @export
 */
export const BusinessMetricEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {'HOUR' | 'DAY' | 'MONTH' | 'YEAR'} [period] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTopEarningAccounts(startDate?: string, endDate?: string, period?: 'HOUR' | 'DAY' | 'MONTH' | 'YEAR', limit?: number, options: any = {}): RequestArgs {
            const localVarPath = `/api/mlpbilling/top-earning-accounts`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (period !== undefined) {
                localVarQueryParameter['period'] = period;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {'HOUR' | 'DAY' | 'MONTH' | 'YEAR'} [period] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTopEarningModels(startDate?: string, endDate?: string, period?: 'HOUR' | 'DAY' | 'MONTH' | 'YEAR', limit?: number, options: any = {}): RequestArgs {
            const localVarPath = `/api/mlpbilling/top-earning-models`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (period !== undefined) {
                localVarQueryParameter['period'] = period;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {'HOUR' | 'DAY' | 'MONTH' | 'YEAR'} [period] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTopSpendingAccounts(startDate?: string, endDate?: string, period?: 'HOUR' | 'DAY' | 'MONTH' | 'YEAR', limit?: number, options: any = {}): RequestArgs {
            const localVarPath = `/api/mlpbilling/top-spending-accounts`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (period !== undefined) {
                localVarQueryParameter['period'] = period;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BusinessMetricEndpointApi - functional programming interface
 * @export
 */
export const BusinessMetricEndpointApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {'HOUR' | 'DAY' | 'MONTH' | 'YEAR'} [period] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTopEarningAccounts(startDate?: string, endDate?: string, period?: 'HOUR' | 'DAY' | 'MONTH' | 'YEAR', limit?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IncomeMetricsResponse>> {
            const localVarAxiosArgs = BusinessMetricEndpointApiAxiosParamCreator(configuration).getTopEarningAccounts(startDate, endDate, period, limit, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {'HOUR' | 'DAY' | 'MONTH' | 'YEAR'} [period] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTopEarningModels(startDate?: string, endDate?: string, period?: 'HOUR' | 'DAY' | 'MONTH' | 'YEAR', limit?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TopEarningModelResponse>> {
            const localVarAxiosArgs = BusinessMetricEndpointApiAxiosParamCreator(configuration).getTopEarningModels(startDate, endDate, period, limit, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {'HOUR' | 'DAY' | 'MONTH' | 'YEAR'} [period] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTopSpendingAccounts(startDate?: string, endDate?: string, period?: 'HOUR' | 'DAY' | 'MONTH' | 'YEAR', limit?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SpendingMetricsResponse>> {
            const localVarAxiosArgs = BusinessMetricEndpointApiAxiosParamCreator(configuration).getTopSpendingAccounts(startDate, endDate, period, limit, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * BusinessMetricEndpointApi - factory interface
 * @export
 */
export const BusinessMetricEndpointApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {'HOUR' | 'DAY' | 'MONTH' | 'YEAR'} [period] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTopEarningAccounts(startDate?: string, endDate?: string, period?: 'HOUR' | 'DAY' | 'MONTH' | 'YEAR', limit?: number, options?: any): AxiosPromise<Array<IncomeMetricsResponse>> {
            return BusinessMetricEndpointApiFp(configuration).getTopEarningAccounts(startDate, endDate, period, limit, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {'HOUR' | 'DAY' | 'MONTH' | 'YEAR'} [period] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTopEarningModels(startDate?: string, endDate?: string, period?: 'HOUR' | 'DAY' | 'MONTH' | 'YEAR', limit?: number, options?: any): AxiosPromise<Array<TopEarningModelResponse>> {
            return BusinessMetricEndpointApiFp(configuration).getTopEarningModels(startDate, endDate, period, limit, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {'HOUR' | 'DAY' | 'MONTH' | 'YEAR'} [period] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTopSpendingAccounts(startDate?: string, endDate?: string, period?: 'HOUR' | 'DAY' | 'MONTH' | 'YEAR', limit?: number, options?: any): AxiosPromise<Array<SpendingMetricsResponse>> {
            return BusinessMetricEndpointApiFp(configuration).getTopSpendingAccounts(startDate, endDate, period, limit, options)(axios, basePath);
        },
    };
};

/**
 * BusinessMetricEndpointApi - object-oriented interface
 * @export
 * @class BusinessMetricEndpointApi
 * @extends {BaseAPI}
 */
export class BusinessMetricEndpointApi extends BaseAPI {
    /**
     * 
     * @param {string} [startDate] 
     * @param {string} [endDate] 
     * @param {'HOUR' | 'DAY' | 'MONTH' | 'YEAR'} [period] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessMetricEndpointApi
     */
    public getTopEarningAccounts(startDate?: string, endDate?: string, period?: 'HOUR' | 'DAY' | 'MONTH' | 'YEAR', limit?: number, options?: any) {
        return BusinessMetricEndpointApiFp(this.configuration).getTopEarningAccounts(startDate, endDate, period, limit, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [startDate] 
     * @param {string} [endDate] 
     * @param {'HOUR' | 'DAY' | 'MONTH' | 'YEAR'} [period] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessMetricEndpointApi
     */
    public getTopEarningModels(startDate?: string, endDate?: string, period?: 'HOUR' | 'DAY' | 'MONTH' | 'YEAR', limit?: number, options?: any) {
        return BusinessMetricEndpointApiFp(this.configuration).getTopEarningModels(startDate, endDate, period, limit, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [startDate] 
     * @param {string} [endDate] 
     * @param {'HOUR' | 'DAY' | 'MONTH' | 'YEAR'} [period] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessMetricEndpointApi
     */
    public getTopSpendingAccounts(startDate?: string, endDate?: string, period?: 'HOUR' | 'DAY' | 'MONTH' | 'YEAR', limit?: number, options?: any) {
        return BusinessMetricEndpointApiFp(this.configuration).getTopSpendingAccounts(startDate, endDate, period, limit, options)(this.axios, this.basePath);
    }

}


/**
 * InternalEndpointApi - axios parameter creator
 * @export
 */
export const InternalEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLimits1(accountId: number, options: any = {}): RequestArgs {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling getLimits1.');
            }
            const localVarPath = `/api/mlpbilling-internal/account-limits/{accountId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LimitsRequest} limitsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMultipleLimits1(limitsRequest: LimitsRequest, options: any = {}): RequestArgs {
            // verify required parameter 'limitsRequest' is not null or undefined
            if (limitsRequest === null || limitsRequest === undefined) {
                throw new RequiredError('limitsRequest','Required parameter limitsRequest was null or undefined when calling getMultipleLimits1.');
            }
            const localVarPath = `/api/mlpbilling-internal/account-limits/list`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof limitsRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(limitsRequest !== undefined ? limitsRequest : {}) : (limitsRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} accountId 
         * @param {'HOUR' | 'DAY' | 'MONTH' | 'YEAR'} period 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRawServerSpendingHistory(accountId: number, period: 'HOUR' | 'DAY' | 'MONTH' | 'YEAR', date: string, options: any = {}): RequestArgs {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling getRawServerSpendingHistory.');
            }
            // verify required parameter 'period' is not null or undefined
            if (period === null || period === undefined) {
                throw new RequiredError('period','Required parameter period was null or undefined when calling getRawServerSpendingHistory.');
            }
            // verify required parameter 'date' is not null or undefined
            if (date === null || date === undefined) {
                throw new RequiredError('date','Required parameter date was null or undefined when calling getRawServerSpendingHistory.');
            }
            const localVarPath = `/api/mlpbilling-internal/account/{accountId}/details/spending/server/raw`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }

            if (period !== undefined) {
                localVarQueryParameter['period'] = period;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} accountId 
         * @param {'HOUR' | 'DAY' | 'MONTH' | 'YEAR'} period 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServerSpendingHistory1(accountId: number, period: 'HOUR' | 'DAY' | 'MONTH' | 'YEAR', date: string, options: any = {}): RequestArgs {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling getServerSpendingHistory1.');
            }
            // verify required parameter 'period' is not null or undefined
            if (period === null || period === undefined) {
                throw new RequiredError('period','Required parameter period was null or undefined when calling getServerSpendingHistory1.');
            }
            // verify required parameter 'date' is not null or undefined
            if (date === null || date === undefined) {
                throw new RequiredError('date','Required parameter date was null or undefined when calling getServerSpendingHistory1.');
            }
            const localVarPath = `/api/mlpbilling-internal/account/{accountId}/details/spending/server`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }

            if (period !== undefined) {
                localVarQueryParameter['period'] = period;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runCheckBlocked(options: any = {}): RequestArgs {
            const localVarPath = `/api/mlpbilling-internal/run-check-blocked`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateLimitsRequest} updateLimitsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setLimits1(updateLimitsRequest: UpdateLimitsRequest, options: any = {}): RequestArgs {
            // verify required parameter 'updateLimitsRequest' is not null or undefined
            if (updateLimitsRequest === null || updateLimitsRequest === undefined) {
                throw new RequiredError('updateLimitsRequest','Required parameter updateLimitsRequest was null or undefined when calling setLimits1.');
            }
            const localVarPath = `/api/mlpbilling-internal/account-limits`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof updateLimitsRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateLimitsRequest !== undefined ? updateLimitsRequest : {}) : (updateLimitsRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} accountId 
         * @param {ServerSpendRequestV2} serverSpendRequestV2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spendOnServer1(accountId: number, serverSpendRequestV2: ServerSpendRequestV2, options: any = {}): RequestArgs {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling spendOnServer1.');
            }
            // verify required parameter 'serverSpendRequestV2' is not null or undefined
            if (serverSpendRequestV2 === null || serverSpendRequestV2 === undefined) {
                throw new RequiredError('serverSpendRequestV2','Required parameter serverSpendRequestV2 was null or undefined when calling spendOnServer1.');
            }
            const localVarPath = `/api/mlpbilling-internal/account/{accountId}/spend/server`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof serverSpendRequestV2 !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(serverSpendRequestV2 !== undefined ? serverSpendRequestV2 : {}) : (serverSpendRequestV2 || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InternalEndpointApi - functional programming interface
 * @export
 */
export const InternalEndpointApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLimits1(accountId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LimitsAndBalance> {
            const localVarAxiosArgs = InternalEndpointApiAxiosParamCreator(configuration).getLimits1(accountId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {LimitsRequest} limitsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMultipleLimits1(limitsRequest: LimitsRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LimitsAndBalances> {
            const localVarAxiosArgs = InternalEndpointApiAxiosParamCreator(configuration).getMultipleLimits1(limitsRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} accountId 
         * @param {'HOUR' | 'DAY' | 'MONTH' | 'YEAR'} period 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRawServerSpendingHistory(accountId: number, period: 'HOUR' | 'DAY' | 'MONTH' | 'YEAR', date: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ServerSpendHistory>> {
            const localVarAxiosArgs = InternalEndpointApiAxiosParamCreator(configuration).getRawServerSpendingHistory(accountId, period, date, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} accountId 
         * @param {'HOUR' | 'DAY' | 'MONTH' | 'YEAR'} period 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServerSpendingHistory1(accountId: number, period: 'HOUR' | 'DAY' | 'MONTH' | 'YEAR', date: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServerSpendingDetailsDto> {
            const localVarAxiosArgs = InternalEndpointApiAxiosParamCreator(configuration).getServerSpendingHistory1(accountId, period, date, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runCheckBlocked(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = InternalEndpointApiAxiosParamCreator(configuration).runCheckBlocked(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {UpdateLimitsRequest} updateLimitsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setLimits1(updateLimitsRequest: UpdateLimitsRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = InternalEndpointApiAxiosParamCreator(configuration).setLimits1(updateLimitsRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} accountId 
         * @param {ServerSpendRequestV2} serverSpendRequestV2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spendOnServer1(accountId: number, serverSpendRequestV2: ServerSpendRequestV2, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = InternalEndpointApiAxiosParamCreator(configuration).spendOnServer1(accountId, serverSpendRequestV2, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * InternalEndpointApi - factory interface
 * @export
 */
export const InternalEndpointApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLimits1(accountId: number, options?: any): AxiosPromise<LimitsAndBalance> {
            return InternalEndpointApiFp(configuration).getLimits1(accountId, options)(axios, basePath);
        },
        /**
         * 
         * @param {LimitsRequest} limitsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMultipleLimits1(limitsRequest: LimitsRequest, options?: any): AxiosPromise<LimitsAndBalances> {
            return InternalEndpointApiFp(configuration).getMultipleLimits1(limitsRequest, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} accountId 
         * @param {'HOUR' | 'DAY' | 'MONTH' | 'YEAR'} period 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRawServerSpendingHistory(accountId: number, period: 'HOUR' | 'DAY' | 'MONTH' | 'YEAR', date: string, options?: any): AxiosPromise<Array<ServerSpendHistory>> {
            return InternalEndpointApiFp(configuration).getRawServerSpendingHistory(accountId, period, date, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} accountId 
         * @param {'HOUR' | 'DAY' | 'MONTH' | 'YEAR'} period 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServerSpendingHistory1(accountId: number, period: 'HOUR' | 'DAY' | 'MONTH' | 'YEAR', date: string, options?: any): AxiosPromise<ServerSpendingDetailsDto> {
            return InternalEndpointApiFp(configuration).getServerSpendingHistory1(accountId, period, date, options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runCheckBlocked(options?: any): AxiosPromise<void> {
            return InternalEndpointApiFp(configuration).runCheckBlocked(options)(axios, basePath);
        },
        /**
         * 
         * @param {UpdateLimitsRequest} updateLimitsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setLimits1(updateLimitsRequest: UpdateLimitsRequest, options?: any): AxiosPromise<void> {
            return InternalEndpointApiFp(configuration).setLimits1(updateLimitsRequest, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} accountId 
         * @param {ServerSpendRequestV2} serverSpendRequestV2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spendOnServer1(accountId: number, serverSpendRequestV2: ServerSpendRequestV2, options?: any): AxiosPromise<void> {
            return InternalEndpointApiFp(configuration).spendOnServer1(accountId, serverSpendRequestV2, options)(axios, basePath);
        },
    };
};

/**
 * InternalEndpointApi - object-oriented interface
 * @export
 * @class InternalEndpointApi
 * @extends {BaseAPI}
 */
export class InternalEndpointApi extends BaseAPI {
    /**
     * 
     * @param {number} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalEndpointApi
     */
    public getLimits1(accountId: number, options?: any) {
        return InternalEndpointApiFp(this.configuration).getLimits1(accountId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {LimitsRequest} limitsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalEndpointApi
     */
    public getMultipleLimits1(limitsRequest: LimitsRequest, options?: any) {
        return InternalEndpointApiFp(this.configuration).getMultipleLimits1(limitsRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} accountId 
     * @param {'HOUR' | 'DAY' | 'MONTH' | 'YEAR'} period 
     * @param {string} date 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalEndpointApi
     */
    public getRawServerSpendingHistory(accountId: number, period: 'HOUR' | 'DAY' | 'MONTH' | 'YEAR', date: string, options?: any) {
        return InternalEndpointApiFp(this.configuration).getRawServerSpendingHistory(accountId, period, date, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} accountId 
     * @param {'HOUR' | 'DAY' | 'MONTH' | 'YEAR'} period 
     * @param {string} date 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalEndpointApi
     */
    public getServerSpendingHistory1(accountId: number, period: 'HOUR' | 'DAY' | 'MONTH' | 'YEAR', date: string, options?: any) {
        return InternalEndpointApiFp(this.configuration).getServerSpendingHistory1(accountId, period, date, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalEndpointApi
     */
    public runCheckBlocked(options?: any) {
        return InternalEndpointApiFp(this.configuration).runCheckBlocked(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {UpdateLimitsRequest} updateLimitsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalEndpointApi
     */
    public setLimits1(updateLimitsRequest: UpdateLimitsRequest, options?: any) {
        return InternalEndpointApiFp(this.configuration).setLimits1(updateLimitsRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} accountId 
     * @param {ServerSpendRequestV2} serverSpendRequestV2 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalEndpointApi
     */
    public spendOnServer1(accountId: number, serverSpendRequestV2: ServerSpendRequestV2, options?: any) {
        return InternalEndpointApiFp(this.configuration).spendOnServer1(accountId, serverSpendRequestV2, options)(this.axios, this.basePath);
    }

}


/**
 * RefillEndpointApi - axios parameter creator
 * @export
 */
export const RefillEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRefillHistory(accountId: number, options: any = {}): RequestArgs {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling getRefillHistory.');
            }
            const localVarPath = `/api/mlpbilling/account/{accountId}/refill/history`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} accountId 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        grantWelcomeBonus(accountId: number, userId: number, options: any = {}): RequestArgs {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling grantWelcomeBonus.');
            }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling grantWelcomeBonus.');
            }
            const localVarPath = `/api/mlpbilling/account/{accountId}/user/{userId}/refill/welcome-bonus`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} accountId 
         * @param {number} userId 
         * @param {RefillFromCardDynamicRequest} refillFromCardDynamicRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refillFromCardDynamic(accountId: number, userId: number, refillFromCardDynamicRequest: RefillFromCardDynamicRequest, options: any = {}): RequestArgs {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling refillFromCardDynamic.');
            }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling refillFromCardDynamic.');
            }
            // verify required parameter 'refillFromCardDynamicRequest' is not null or undefined
            if (refillFromCardDynamicRequest === null || refillFromCardDynamicRequest === undefined) {
                throw new RequiredError('refillFromCardDynamicRequest','Required parameter refillFromCardDynamicRequest was null or undefined when calling refillFromCardDynamic.');
            }
            const localVarPath = `/api/mlpbilling/account/{accountId}/user/{userId}/refill/card/dynamic`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof refillFromCardDynamicRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(refillFromCardDynamicRequest !== undefined ? refillFromCardDynamicRequest : {}) : (refillFromCardDynamicRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} accountId 
         * @param {number} userId 
         * @param {RefillFromIncomeRequest} refillFromIncomeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refillFromIncomeBalance(accountId: number, userId: number, refillFromIncomeRequest: RefillFromIncomeRequest, options: any = {}): RequestArgs {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling refillFromIncomeBalance.');
            }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling refillFromIncomeBalance.');
            }
            // verify required parameter 'refillFromIncomeRequest' is not null or undefined
            if (refillFromIncomeRequest === null || refillFromIncomeRequest === undefined) {
                throw new RequiredError('refillFromIncomeRequest','Required parameter refillFromIncomeRequest was null or undefined when calling refillFromIncomeBalance.');
            }
            const localVarPath = `/api/mlpbilling/account/{accountId}/user/{userId}/refill/income`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof refillFromIncomeRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(refillFromIncomeRequest !== undefined ? refillFromIncomeRequest : {}) : (refillFromIncomeRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RefillEndpointApi - functional programming interface
 * @export
 */
export const RefillEndpointApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRefillHistory(accountId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RefillHistory>> {
            const localVarAxiosArgs = RefillEndpointApiAxiosParamCreator(configuration).getRefillHistory(accountId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} accountId 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        grantWelcomeBonus(accountId: number, userId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = RefillEndpointApiAxiosParamCreator(configuration).grantWelcomeBonus(accountId, userId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} accountId 
         * @param {number} userId 
         * @param {RefillFromCardDynamicRequest} refillFromCardDynamicRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refillFromCardDynamic(accountId: number, userId: number, refillFromCardDynamicRequest: RefillFromCardDynamicRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RefillFromCardResponse> {
            const localVarAxiosArgs = RefillEndpointApiAxiosParamCreator(configuration).refillFromCardDynamic(accountId, userId, refillFromCardDynamicRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} accountId 
         * @param {number} userId 
         * @param {RefillFromIncomeRequest} refillFromIncomeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refillFromIncomeBalance(accountId: number, userId: number, refillFromIncomeRequest: RefillFromIncomeRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = RefillEndpointApiAxiosParamCreator(configuration).refillFromIncomeBalance(accountId, userId, refillFromIncomeRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * RefillEndpointApi - factory interface
 * @export
 */
export const RefillEndpointApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRefillHistory(accountId: number, options?: any): AxiosPromise<Array<RefillHistory>> {
            return RefillEndpointApiFp(configuration).getRefillHistory(accountId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} accountId 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        grantWelcomeBonus(accountId: number, userId: number, options?: any): AxiosPromise<void> {
            return RefillEndpointApiFp(configuration).grantWelcomeBonus(accountId, userId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} accountId 
         * @param {number} userId 
         * @param {RefillFromCardDynamicRequest} refillFromCardDynamicRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refillFromCardDynamic(accountId: number, userId: number, refillFromCardDynamicRequest: RefillFromCardDynamicRequest, options?: any): AxiosPromise<RefillFromCardResponse> {
            return RefillEndpointApiFp(configuration).refillFromCardDynamic(accountId, userId, refillFromCardDynamicRequest, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} accountId 
         * @param {number} userId 
         * @param {RefillFromIncomeRequest} refillFromIncomeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refillFromIncomeBalance(accountId: number, userId: number, refillFromIncomeRequest: RefillFromIncomeRequest, options?: any): AxiosPromise<void> {
            return RefillEndpointApiFp(configuration).refillFromIncomeBalance(accountId, userId, refillFromIncomeRequest, options)(axios, basePath);
        },
    };
};

/**
 * RefillEndpointApi - object-oriented interface
 * @export
 * @class RefillEndpointApi
 * @extends {BaseAPI}
 */
export class RefillEndpointApi extends BaseAPI {
    /**
     * 
     * @param {number} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RefillEndpointApi
     */
    public getRefillHistory(accountId: number, options?: any) {
        return RefillEndpointApiFp(this.configuration).getRefillHistory(accountId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} accountId 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RefillEndpointApi
     */
    public grantWelcomeBonus(accountId: number, userId: number, options?: any) {
        return RefillEndpointApiFp(this.configuration).grantWelcomeBonus(accountId, userId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} accountId 
     * @param {number} userId 
     * @param {RefillFromCardDynamicRequest} refillFromCardDynamicRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RefillEndpointApi
     */
    public refillFromCardDynamic(accountId: number, userId: number, refillFromCardDynamicRequest: RefillFromCardDynamicRequest, options?: any) {
        return RefillEndpointApiFp(this.configuration).refillFromCardDynamic(accountId, userId, refillFromCardDynamicRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} accountId 
     * @param {number} userId 
     * @param {RefillFromIncomeRequest} refillFromIncomeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RefillEndpointApi
     */
    public refillFromIncomeBalance(accountId: number, userId: number, refillFromIncomeRequest: RefillFromIncomeRequest, options?: any) {
        return RefillEndpointApiFp(this.configuration).refillFromIncomeBalance(accountId, userId, refillFromIncomeRequest, options)(this.axios, this.basePath);
    }

}


/**
 * SpendingEndpointApi - axios parameter creator
 * @export
 */
export const SpendingEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} accountId 
         * @param {SpendRequest} spendRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spend(accountId: number, spendRequest: SpendRequest, options: any = {}): RequestArgs {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling spend.');
            }
            // verify required parameter 'spendRequest' is not null or undefined
            if (spendRequest === null || spendRequest === undefined) {
                throw new RequiredError('spendRequest','Required parameter spendRequest was null or undefined when calling spend.');
            }
            const localVarPath = `/api/mlpbilling/account/{accountId}/spend`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof spendRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(spendRequest !== undefined ? spendRequest : {}) : (spendRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BatchSpendRequests} batchSpendRequests 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spendBatch(batchSpendRequests: BatchSpendRequests, options: any = {}): RequestArgs {
            // verify required parameter 'batchSpendRequests' is not null or undefined
            if (batchSpendRequests === null || batchSpendRequests === undefined) {
                throw new RequiredError('batchSpendRequests','Required parameter batchSpendRequests was null or undefined when calling spendBatch.');
            }
            const localVarPath = `/api/mlpbilling/spend/batch`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof batchSpendRequests !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(batchSpendRequests !== undefined ? batchSpendRequests : {}) : (batchSpendRequests || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SpendRequests} spendRequests 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spendMultiple(spendRequests: SpendRequests, options: any = {}): RequestArgs {
            // verify required parameter 'spendRequests' is not null or undefined
            if (spendRequests === null || spendRequests === undefined) {
                throw new RequiredError('spendRequests','Required parameter spendRequests was null or undefined when calling spendMultiple.');
            }
            const localVarPath = `/api/mlpbilling/spend/multiple`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof spendRequests !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(spendRequests !== undefined ? spendRequests : {}) : (spendRequests || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} accountId 
         * @param {ServerSpendRequest} serverSpendRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spendOnServer(accountId: number, serverSpendRequest: ServerSpendRequest, options: any = {}): RequestArgs {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling spendOnServer.');
            }
            // verify required parameter 'serverSpendRequest' is not null or undefined
            if (serverSpendRequest === null || serverSpendRequest === undefined) {
                throw new RequiredError('serverSpendRequest','Required parameter serverSpendRequest was null or undefined when calling spendOnServer.');
            }
            const localVarPath = `/api/mlpbilling/account/{accountId}/spend/server`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof serverSpendRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(serverSpendRequest !== undefined ? serverSpendRequest : {}) : (serverSpendRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} accountId 
         * @param {ServerSpendRequestV2} serverSpendRequestV2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spendOnServerV2(accountId: number, serverSpendRequestV2: ServerSpendRequestV2, options: any = {}): RequestArgs {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling spendOnServerV2.');
            }
            // verify required parameter 'serverSpendRequestV2' is not null or undefined
            if (serverSpendRequestV2 === null || serverSpendRequestV2 === undefined) {
                throw new RequiredError('serverSpendRequestV2','Required parameter serverSpendRequestV2 was null or undefined when calling spendOnServerV2.');
            }
            const localVarPath = `/api/mlpbilling/account/{accountId}/spend/server/v2`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof serverSpendRequestV2 !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(serverSpendRequestV2 !== undefined ? serverSpendRequestV2 : {}) : (serverSpendRequestV2 || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SpendingEndpointApi - functional programming interface
 * @export
 */
export const SpendingEndpointApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} accountId 
         * @param {SpendRequest} spendRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spend(accountId: number, spendRequest: SpendRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = SpendingEndpointApiAxiosParamCreator(configuration).spend(accountId, spendRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {BatchSpendRequests} batchSpendRequests 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spendBatch(batchSpendRequests: BatchSpendRequests, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = SpendingEndpointApiAxiosParamCreator(configuration).spendBatch(batchSpendRequests, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {SpendRequests} spendRequests 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spendMultiple(spendRequests: SpendRequests, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = SpendingEndpointApiAxiosParamCreator(configuration).spendMultiple(spendRequests, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} accountId 
         * @param {ServerSpendRequest} serverSpendRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spendOnServer(accountId: number, serverSpendRequest: ServerSpendRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = SpendingEndpointApiAxiosParamCreator(configuration).spendOnServer(accountId, serverSpendRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} accountId 
         * @param {ServerSpendRequestV2} serverSpendRequestV2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spendOnServerV2(accountId: number, serverSpendRequestV2: ServerSpendRequestV2, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = SpendingEndpointApiAxiosParamCreator(configuration).spendOnServerV2(accountId, serverSpendRequestV2, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SpendingEndpointApi - factory interface
 * @export
 */
export const SpendingEndpointApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} accountId 
         * @param {SpendRequest} spendRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spend(accountId: number, spendRequest: SpendRequest, options?: any): AxiosPromise<void> {
            return SpendingEndpointApiFp(configuration).spend(accountId, spendRequest, options)(axios, basePath);
        },
        /**
         * 
         * @param {BatchSpendRequests} batchSpendRequests 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spendBatch(batchSpendRequests: BatchSpendRequests, options?: any): AxiosPromise<void> {
            return SpendingEndpointApiFp(configuration).spendBatch(batchSpendRequests, options)(axios, basePath);
        },
        /**
         * 
         * @param {SpendRequests} spendRequests 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spendMultiple(spendRequests: SpendRequests, options?: any): AxiosPromise<void> {
            return SpendingEndpointApiFp(configuration).spendMultiple(spendRequests, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} accountId 
         * @param {ServerSpendRequest} serverSpendRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spendOnServer(accountId: number, serverSpendRequest: ServerSpendRequest, options?: any): AxiosPromise<void> {
            return SpendingEndpointApiFp(configuration).spendOnServer(accountId, serverSpendRequest, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} accountId 
         * @param {ServerSpendRequestV2} serverSpendRequestV2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spendOnServerV2(accountId: number, serverSpendRequestV2: ServerSpendRequestV2, options?: any): AxiosPromise<void> {
            return SpendingEndpointApiFp(configuration).spendOnServerV2(accountId, serverSpendRequestV2, options)(axios, basePath);
        },
    };
};

/**
 * SpendingEndpointApi - object-oriented interface
 * @export
 * @class SpendingEndpointApi
 * @extends {BaseAPI}
 */
export class SpendingEndpointApi extends BaseAPI {
    /**
     * 
     * @param {number} accountId 
     * @param {SpendRequest} spendRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpendingEndpointApi
     */
    public spend(accountId: number, spendRequest: SpendRequest, options?: any) {
        return SpendingEndpointApiFp(this.configuration).spend(accountId, spendRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {BatchSpendRequests} batchSpendRequests 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpendingEndpointApi
     */
    public spendBatch(batchSpendRequests: BatchSpendRequests, options?: any) {
        return SpendingEndpointApiFp(this.configuration).spendBatch(batchSpendRequests, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {SpendRequests} spendRequests 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpendingEndpointApi
     */
    public spendMultiple(spendRequests: SpendRequests, options?: any) {
        return SpendingEndpointApiFp(this.configuration).spendMultiple(spendRequests, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} accountId 
     * @param {ServerSpendRequest} serverSpendRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpendingEndpointApi
     */
    public spendOnServer(accountId: number, serverSpendRequest: ServerSpendRequest, options?: any) {
        return SpendingEndpointApiFp(this.configuration).spendOnServer(accountId, serverSpendRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} accountId 
     * @param {ServerSpendRequestV2} serverSpendRequestV2 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpendingEndpointApi
     */
    public spendOnServerV2(accountId: number, serverSpendRequestV2: ServerSpendRequestV2, options?: any) {
        return SpendingEndpointApiFp(this.configuration).spendOnServerV2(accountId, serverSpendRequestV2, options)(this.axios, this.basePath);
    }

}


/**
 * SystemEndpointApi - axios parameter creator
 * @export
 */
export const SystemEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health(options: any = {}): RequestArgs {
            const localVarPath = `/api/mlpbilling/health`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        version(options: any = {}): RequestArgs {
            const localVarPath = `/api/mlpbilling/version`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SystemEndpointApi - functional programming interface
 * @export
 */
export const SystemEndpointApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<HealthCheckResult> {
            const localVarAxiosArgs = SystemEndpointApiAxiosParamCreator(configuration).health(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        version(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
            const localVarAxiosArgs = SystemEndpointApiAxiosParamCreator(configuration).version(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SystemEndpointApi - factory interface
 * @export
 */
export const SystemEndpointApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health(options?: any): AxiosPromise<HealthCheckResult> {
            return SystemEndpointApiFp(configuration).health(options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        version(options?: any): AxiosPromise<string> {
            return SystemEndpointApiFp(configuration).version(options)(axios, basePath);
        },
    };
};

/**
 * SystemEndpointApi - object-oriented interface
 * @export
 * @class SystemEndpointApi
 * @extends {BaseAPI}
 */
export class SystemEndpointApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemEndpointApi
     */
    public health(options?: any) {
        return SystemEndpointApiFp(this.configuration).health(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemEndpointApi
     */
    public version(options?: any) {
        return SystemEndpointApiFp(this.configuration).version(options)(this.axios, this.basePath);
    }

}


